import React from 'react';
import { createMessage, getTriggeredMessageStatus, sendTriggeredMessage } from '../../../actions/push';
import ResultItem from './resultItem';
import { CONTACT_ID } from './TestSendConstants';

/* eslint-disable no-else-return */

class TestResultContent extends React.Component {
	constructor (props) {
		super(props);

		this.i18n = this.props.i18n;
		this.contactId = this.props.contactId;
		this.testInputValue = this.props.testInputValue;
		this.selectedDevices = this.props.selectedDevices;
		this.appPayload = this.props.appPayload;
		this.msgPayload = this.props.msgPayload;
		this.markFooterComplete = this.props.markFooterComplete;
		this.timelineList = [];
		if (this.contactId === CONTACT_ID.DEVICE_ID) {
			this.state = {
				timelineList: [this.getDummyItem(this.i18n.get('test_send_fetch_system_token_title'))]
			};
		} else {
			this.state = {
				timelineList: [this.getDummyItem(this.i18n.get('test_send_message_created_title'))]
			};
		}
		this.RetryMaxAttempts = 10;
		this.RetryTimeout = 5000;
		this.retryAttempt = 0;
	}

	componentDidMount () {
		this.ismounted = true;
		if (this.contactId !== CONTACT_ID.DEVICE_ID) {
			this.testSend();
		}
	}

	componentWillUnmount () {
		this.ismounted = false;
	}

	updateState = (state) => {
		if (this.ismounted) {
			this.setState(state);
		}
	};

	testSend = () => {
		if (!this.ismounted) {
			return;
		}

		if (this.contactId === CONTACT_ID.DEVICE_ID) {
			let item = {
				mainHeading: this.i18n.get('test_send_fetch_system_token_title'),
				itemId: 'fetch-system-token-article',
				taskAssistiveText: this.i18n.get('test_send_fetch_system_token_title'),
				toggleInfoBtnTitle: this.i18n.get('test_send_fetch_system_token_toggle'),
				toggleInfoAssistiveText: this.i18n.get('test_send_fetch_system_token_assistive')
			};
			if (!this.systemTokenFromDeviceId) {
				let subHeading;
				if (this.err) {
					subHeading = this.i18n.get('test_send_fetch_generic_failure');
				}	else {
					subHeading = this.i18n.get('test_send_fetch_system_token_subtitle_failure');
				}
				item = this.setItemParams(item, false, subHeading, [
					{ key: 'Device ID', value: this.testInputValue }], 'failure');
				this.timelineList.push(item);
				this.updateState({ timelineList: this.timelineList });
				this.markFooterComplete();
				return;
			}	else {
				item = this.setItemParams(item, true, this.i18n.get('test_send_fetch_system_token_subtitle_success'), [], 'success');
				item.hideExpandButton = true;
				this.timelineList.push(item);
			}
		}
		this.createPushMessage();
	};

	createPushMessage = () => {
		if (!this.ismounted) {
			return;
		}
		const createMessagePayload = this.getMessagePayload();
		createMessage(createMessagePayload).then((data) => {
			this.createMessageItem(data);
			if ('id' in data) {
				this.id = data.id;
				this.sendTriggeredMessage();
			}
		});
	};

	sendTriggeredMessage = () => {
		if (!this.ismounted) {
			return;
		}
		let body;

		if (this.contactId === CONTACT_ID.CONTACT_KEY) {
			body = {
				deviceTokens: Array.from(this.selectedDevices.map((device) => device.systemToken))
			};
		} else if (this.contactId === CONTACT_ID.DEVICE_ID) {
			body = {
				deviceTokens: [this.systemTokenFromDeviceId]
			};
		}	else {
			body = {
				deviceTokens: [this.testInputValue]
			};
		}

		sendTriggeredMessage(this.id, body).then((data) => {
			this.sendTriggeredMessageItem(data);
			if ('tokenId' in data) {
				this.deliveryToken = data.tokenId;
				setTimeout(this.getTriggeredMessageStatus, this.RetryTimeout);
			}
		});
	};

	getTriggeredMessageStatus = () => {
		if (!this.ismounted) {
			return;
		}

		getTriggeredMessageStatus(this.id, this.deliveryToken).then((data) => {
			this.getTriggeredMessageStatusItem(data);
			if ('errors' in data) {
				this.markFooterComplete();
			} else if (('status' in data && data.status !== 'Finished' && data.status !== 'Error') || ('messageId' in data && !('status' in data))) {
				if (this.retryAttempt < this.RetryMaxAttempts) {
					this.retryAttempt += 1;
					this.showDummyItem(this.i18n.get('test_send_message_status_retry_title'));
					setTimeout(() => {
						this.timelineList.pop();
						this.getTriggeredMessageStatus();
					}, this.RetryTimeout);
				}	else {
					const retryItem = this.timelineList.pop();
					retryItem.subHeading = this.i18n.get('test_send_message_status_subtitle_push_untracked');
					retryItem.showRetry = true;
					this.timelineList.push(retryItem);
					this.updateState({
						timelineList: this.timelineList
					});
					this.retryAttempt = 0;
				}
			}	else {
				this.markFooterComplete();
			}
		});
	};

	createMessageItem = (data) => {
		let item = {
			mainHeading: this.i18n.get('test_send_message_created_title'),
			itemId: 'create-message-article',
			taskAssistiveText: this.i18n.get('test_send_message_created_title'),
			toggleInfoBtnTitle: this.i18n.get('test_send_message_created_toggle'),
			toggleInfoAssistiveText: this.i18n.get('test_send_message_created_assistive')
		};

		if (!('id' in data)) {
			item = this.setItemParams(item, false, this.i18n.get('test_send_message_created_subtitle_failure_below'), data.errors, 'failure');
			item.hideExpandButton = false;
			this.timelineList.push(item);
			this.updateState({
				timelineList: this.timelineList
			});
			this.markFooterComplete();
		}	else {
			item = this.setItemParams(item, true, this.i18n.get('test_send_message_created_subtitle_success'), [], 'success');
			item.hideExpandButton = true;
			this.timelineList.push(item);
			this.showDummyItem(this.i18n.get('test_send_message_scheduled_title'));
		}
	};

	sendTriggeredMessageItem = (data) => {
		let item = {
			mainHeading: this.i18n.get('test_send_message_scheduled_title'),
			itemId: 'schedule-message-article',
			taskAssistiveText: this.i18n.get('test_send_message_scheduled_title'),
			toggleInfoBtnTitle: this.i18n.get('test_send_message_scheduled_toggle'),
			toggleInfoAssistiveText: this.i18n.get('test_send_message_scheduled_assistive')
		};

		if (!('tokenId' in data)) {
			item = this.setItemParams(item, false, this.i18n.get('test_send_message_scheduled_subtitle_failure_below'), data.errors, 'failure');
			item.hideExpandButton = false;
			this.timelineList.push(item);
			this.updateState({
				timelineList: this.timelineList
			});
			this.markFooterComplete();
		}	else {
			item = this.setItemParams(item, true, this.i18n.get('test_send_message_scheduled_subtitle_success'), [], 'success');
			item.hideExpandButton = true;
			this.timelineList.push(item);
			this.showDummyItem(this.i18n.get('test_send_message_status_title'));
		}
	};

	getTriggeredMessageStatusItem = (data) => {
		let item = {
			mainHeading: this.i18n.get('test_send_message_status_title'),
			itemId: 'message-status-article',
			taskAssistiveText: this.i18n.get('test_send_message_status_title'),
			toggleInfoBtnTitle: this.i18n.get('test_send_message_status_toggle'),
			toggleInfoAssistiveText: this.i18n.get('test_send_message_status_assistive')
		};
		if ('errors' in data) {
			item = this.setItemParams(item, false, this.i18n.get('test_send_message_status_subtitle_failure_below'), data.errors, 'failure');
			item.hideExpandButton = false;
			this.timelineList.push(item);
		} else {
			item = this.setItemParams(item, true, this.i18n.get('test_send_message_status_subtitle_success'), [], 'success');
			if (('status' in data && data.status !== 'Finished' && data.status !== 'Error') || ('messageId' in data && !('status' in data))) {
				item.queued = true;
				item.subHeading = this.i18n.get('test_send_message_status_subtitle_retry');
				item.hideExpandButton = true;
				item.iconType = 'queued';
			}	else if (data.status !== 'Finished') {
				this.setItemParams(item, false, this.i18n.get('test_send_message_status_subtitle_failure_below'), [
					{ key: this.i18n.get('test_send_result_item_header_validation_error'), value: this.i18n.get('test_send_result_item_header_snag') }
				], 'failed');
			} else {
				item.hideExpandButton = true;
			}
			this.timelineList[this.timelineList.length - 1].last = false;
			this.timelineList.push(item);
		}
		this.updateState({
			timelineList: this.timelineList
		});
	};

	showDummyItem = (heading) => {
		this.timelineList.push(this.getDummyItem(heading));
		this.updateState({
			timelineList: [...this.timelineList]
		});
		this.timelineList.pop();
	};

	setItemParams = (item, success, subHeading, articleListMap, iconType) => {
		item.success = success;
		item.subHeading = subHeading;
		item.articleListMap = articleListMap;
		item.iconType = iconType;
		return item;
	};

	getMessagePayload = () => { // update with latest
		const date = new Date();
		const payload = {
			messageType: 7, // InApp Activity
			contentType: 1, // Alert
			name: `TEST-${this.msgPayload.name}`,
			application: {
				id: this.appPayload.id,
				name: this.appPayload.name ? this.appPayload.name : ''
			},
			sendInitiator: 1, // API Type
			status: 2, // Active -> immediate Send
			startDate: new Date(date.setDate(date.getDate() - 5)), // Setting a date 5 days in the past
			'content-available': 1,
			isTestSend: true,
			assetId: this.msgPayload.id
		};
		return payload;
	};

	getDummyItem = (heading) => ({
		mainHeading: heading,
		subHeading: '',
		itemId: 'disabled-article',
		articleListMap: [],
		iconType: 'progress',
		dummy: true,
		hideExpandButton: true,
		last: true,
		taskAssistiveText: this.i18n.get('test_send_dummy_assistive'),
		toggleInfoBtnTitle: this.i18n.get('test_send_dummy_toggle'),
		toggleInfoAssistiveText: this.i18n.get('test_send_dummy_assistive')
	});

	retryButtonHandler = () => {
		this.timelineList.pop();
		this.getTriggeredMessageStatus();
		this.showDummyItem(this.i18n.get('test_send_message_status_title'));
	};

	render () {
		let timeline = '';
		const list = this.state.timelineList;
		if (list.length > 0) {
			list[list.length - 1].last = true;
			timeline = this.state.timelineList.map((resultItem) => (
				<ResultItem
					mainHeading={resultItem.mainHeading}
					subHeading={resultItem.subHeading}
					itemId={resultItem.itemId}
					key={resultItem.itemId}
					articleListMap={resultItem.articleListMap}
					iconType={resultItem.iconType}
					success={!!resultItem.success}
					queued={!!resultItem.queued}
					dummy={!!resultItem.dummy}
					last={!!resultItem.last}
					taskAssistiveText={resultItem.taskAssistiveText}
					toggleInfoBtnTitle={resultItem.toggleInfoBtnTitle}
					hideExpandButton={!!resultItem.hideExpandButton}
					retryButtonHandler={this.retryButtonHandler}
					showRetry={!!resultItem.showRetry}
				/>
			));
		}
		return (
			<div className="progress-modal-test-results">
				<ul className="slds-timeline">
					{timeline}
				</ul>
			</div>
		);
	}
}

export default TestResultContent;
