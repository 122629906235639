/* eslint-disable  react/no-unused-class-component-methods */

import React from 'react';
import { Textarea } from '@salesforce/design-system-react';
import { CONTACT_ID } from './TestSendConstants';
import { fetchContactKeysForDeviceId, fetchSystemTokenForContactKey } from '../../../actions/contacts';

class TestInputContent extends React.Component {
	constructor (props) {
		super(props);
		this.contactId = this.props.contactId;
		this.i18n = this.props.i18n;
		this.updateSystemTokenFromDeviceId = this.props.updateSystemTokenFromDeviceId;
		this.ContactKeysPerDeviceIDLimit = 20;
		this.appId = this.props.appId;
	}

	valueIsNotNull = (value) => value.definitionKey !== undefined && value.innerValue !== undefined && value.innerValue.length !== 0;

	findSystemToken = (responseItems) => {
		// avoiding forEach for early exit.
		for (let i = 0; i < responseItems.length; i++) {
			const item = responseItems[i];
			if (item.values !== undefined && item.values.length !== 0) {
				const { values } = item;
				let deviceId = null; let appId = null; let
					systemToken = null;
				for (let j = 0; j < values.length; j++) {
					const value = values[j];
					if (this.valueIsNotNull(value)) {
						if (value.definitionKey === 'PushAppID' && value.innerValue === this.appId) {
							appId = value.innerValue;
						}
						if (value.definitionKey === 'PushDeviceID' && value.innerValue === this.deviceId) {
							deviceId = value.innerValue;
						}
						if (value.definitionKey === 'SystemToken') {
							systemToken = value.innerValue;
						}
						if (appId !== null && deviceId !== null && systemToken !== null) {
							this.systemToken = systemToken;
							break;
						}
					}
				}
				if (this.systemToken !== null) {
					break;
				}
			}
		}
	};

	getSystemTokenForContactKey = () => {
		const key = this.contactKeys[this.keyIdx];

		fetchSystemTokenForContactKey(key).then((items) => {
			if ('errorcode' in items) {
				this.updateSystemTokenFromDeviceId(null, items.message);
			}	else {
				this.findSystemToken(items);
				if (this.systemToken === null && this.keyIdx !== this.contactKeys.length) {
					this.keyIdx += 1;
					this.getSystemTokenForContactKey();
				} else {
					this.updateSystemTokenFromDeviceId(this.systemToken, null);
				}
			}
		});
	};

	fetchSystemTokenForDeviceId = (deviceID) => {
		this.deviceId = deviceID;
		fetchContactKeysForDeviceId(deviceID).then((contactKeys) => {
			if ('errorcode' in contactKeys) {
				this.updateSystemTokenFromDeviceId(null, contactKeys.message);
			}	else if (contactKeys.contactKeys.length === 0 || contactKeys.contactKeys.length > this.ContactKeysPerDeviceIDLimit) {
				this.updateSystemTokenFromDeviceId(null, null);
			} else {
				this.contactKeys = contactKeys.contactKeys;
				this.keyIdx = 0;
				this.getSystemTokenForContactKey();
				this.systemToken = null;
			}
		});
	};

	getLabelForContactType = () => {
		switch (this.contactId) {
			case CONTACT_ID.SYSTEM_TOKEN:
				return this.i18n.get('test_send_system_token_placeholder');
			case CONTACT_ID.DEVICE_ID:
				return this.i18n.get('test_send_device_id_placeholder');
			case CONTACT_ID.CONTACT_KEY:
				return this.i18n.get('test_send_contact_key_placeholder');
			default:
				return '';
		}
	};

	render () {
		return (
			<Textarea
				aria-describedby="error-1"
				id="contact-id-input"
				name="required-textarea-error"
				label={this.getLabelForContactType()}
				required
				errorText=""
				placeholder={this.i18n.get('test_send_area_placeholder')}
				onInput={this.props.testInputHandler}
				defaultValue={this.props.inputValue}
			/>
		);
	}
}

export default TestInputContent;
