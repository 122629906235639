// React stuff
import React from 'react';
import { hot } from 'react-hot-loader';
import { compose } from 'redux';
import { Provider, connect } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Majik stuff
import MAJIK from '@salesforce-mc/majik';
import ActivitySDK from '@salesforce-mc/activitysdk';
import MAJIKMessageActivity from '@salesforce-mc/majik-message-activity';
import MAJIKChannelAnalytics from '@salesforce-mc/majik-channel-analytics';

// Utilities
import isEmpty from 'lodash/isEmpty';
import find from 'lodash.find';
import MCLib from '@salesforce-mc/mc-lib';
import moment from 'moment';

// Icon
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import appIcon from '!raw-loader!../../static/images/inapp_icon_with_background.svg'; // eslint-disable-line import/no-unresolved
import messageCardAppIcon from '!raw-loader!../../static/images/inapp_icon.svg'; // eslint-disable-line import/no-unresolved

import I18n from '../utilities/i18n';
import PostmongerStore from '../utilities/postmonger';
import { observeAndUnmountReact, hasProperty, generateUUID, sanitizeHTML, isMessageSelected } from '../utilities/helper';
import betterValidationExtension from '../utilities/better-validation-extension';

// React Containers
import MessageConfiguration from './message-configuration';
import DeliveryOptions from './delivery-options';
import AdvancedOptions from './advanced-options';
import SummaryDetail from './summary-detail';

// React actions
import { fetchMobileApps } from '../actions/mobile-app-selection';
import fetchApplicationTriggerEvents from '../actions/applications';
import fetchSettings from '../actions/setting';

// Constants
import { MAJIK_DONE_BUTTON_ID, MAJIK_DETAIL_BUTTON_ID, MESSAGE_TRIGGER_EVENT_TYPE_MAP } from '../constants'; // eslint-disable-line import/no-duplicates

// View configs
import {
	MessageTriggerView,
	FtuxMessageTrigger
} from './view-configs';

import TestSendsModal from '../components/message-configuration/test-sends';

// Mock data for edit flow. Uncomment to use
// import { EDIT_FLOW_MOCK_DATA } from '../constants'; // eslint-disable-line import/no-duplicates

// Mock data for analytics flow. Uncoment to use
// import { ANALYTICS_FLOW_MOCK_DATA } from '../constants'; // eslint-disable-line import/no-duplicates

const sectionKeys = [
	'messageDefinition',
	'messageConfiguration',
	'messageTrigger',
	'deliveryOptions',
	'advancedOptions'
];

const isDetailViewDataAvailable = (content) => content && !isEmpty(content) && ((hasProperty(content, 'views') && hasProperty(content.views, 'inApp') && hasProperty(content.views.inApp, 'content')) || hasProperty(content, 'content'));

const isApplicationSelected = (store) => {
	if (!store || !hasProperty(store, 'messageConfiguration') || !hasProperty(store.messageConfiguration, 'payload')) {
		return false;
	}

	const { payload } = store.messageConfiguration;

	return hasProperty(payload, 'application') && hasProperty(payload.application, 'id') && hasProperty(payload.application, 'name') && payload.application.id.length > 0 && payload.application.name.length > 0;
};

class App extends React.Component {
	constructor (props) {
		super(props);

		this.activitySDK = new ActivitySDK();
		this.cultureCode = PostmongerStore.cultureCode;
		this.i18n = I18n(this.cultureCode);
		this.GUIDForMobilePushReportingName = generateUUID().replace(/-/g, '');

		this.createSummaryView = this.createSummaryView.bind(this);
		this.createMessageDefinitionView = this.createMessageDefinitionView.bind(this);
		this.createMessageConfigView = this.createMessageConfigView.bind(this);
		this.createDeliveryOptionsView = this.createDeliveryOptionsView.bind(this);
		this.createAdvancedOptionsView = this.createAdvancedOptionsView.bind(this);

		moment.locale(this.i18n.getMomentLocale());

		window.inapp = {};

		this.localization = {
			// HubAndSpoke extension keys:
			majik_hs_show_more: this.i18n.get('majik_button_show_more'),
			majik_hs_show_less: this.i18n.get('majik_button_show_less'),
			majik_hs_cancel_button: this.i18n.get('majik_button_cancel'),
			majik_hs_summary_button: this.i18n.get('majik_button_summary'),
			majik_hs_spoke_view_button: this.i18n.get('majik_button_view'),
			majik_hs_spoke_edit_button: this.i18n.get('majik_button_edit'),
			majik_hs_close_message_confirm: this.i18n.get('majik_button_confirm'),
			majik_hs_close_message_cancel: this.i18n.get('majik_button_cancel'),
			majik_hs_close_message_title: this.i18n.get('majik_label_close_message_title'),
			majik_hs_close_message_body: this.i18n.get('majik_label_close_message_body'),

			// ContentView extension keys:
			majik_ce_message_definition_header: this.i18n.get('inapp_message_definition_header'),
			majik_ce_message_definition: this.i18n.get('majik_label_message_definition'),
			majik_ce_message_definition_menu: this.i18n.get('majik_label_message_definition_menu'),
			majik_ce_create_message_link: this.i18n.get('majik_button_create_message_link'),
			majik_ce_edit_message_link: this.i18n.get('majik_button_edit_message_link'),
			majik_ce_content_builder_tab: this.i18n.get('majik_button_content_builder_messages'),
			majik_ce_activity_name: this.i18n.get('majik_label_activity_name'),
			majik_ce_activity_desc: this.i18n.get('majik_label_activity_description'),
			majik_ce_summary_menu: this.i18n.get('majik_button_summary'),
			majik_ce_detail_view_btn: this.i18n.get('majik_button_detail_view'),
			majik_ce_summary_view_btn: this.i18n.get('majik_button_summary_view'),
			majik_ce_done_btn: this.i18n.get('majik_button_done'),
			majik_ce_summary_header: this.i18n.get('inapp_message_activity_summary'),
			majik_ce_summary_select_message_btn: this.i18n.get('majik_button_select_message'),
			majik_ce_summary_new_message_btn: this.i18n.get('majik_button_new_message'),
			majik_ce_summary_edit_message_btn: this.i18n.get('majik_button_edit_message'),
			majik_ce_summary_call_to_action_lg: this.i18n.get('majik_label_summary_call_to_action_lg'),
			majik_ce_summary_call_to_action_sm: this.i18n.get('majik_label_summary_call_to_action_sm'),
			majik_ce_save_and_return: this.i18n.get('majik_button_save_and_return'),
			majik_ce_save_message: this.i18n.get('majik_button_save_message'),
			majik_ce_message_properties: this.i18n.get('majik_label_message_properties'),
			majik_ce_edit_properties: this.i18n.get('majik_button_edit_properties'),
			majik_ce_view_properties: this.i18n.get('majik_button_view_properties'),
			majik_select_different_message: this.i18n.get('majik_button_select_different_message'),
			majik_ce_create_new_message: this.i18n.get('majik_button_create_new_message'),
			majik_ce_refresh_activity: this.i18n.get('majik_button_refresh_activity'),
			majik_ce_close_editor_message_body: this.i18n.get('majik_label_close_message_body'),
			majik_ce_preview_subscribers: this.i18n.get('majik_button_subscriber_preview'),
			majik_ce_error_view_main_text: this.i18n.get('majik_label_error_view_main_text'),
			majik_ce_error_view_sub_text: this.i18n.get('majik_label_error_view_sub_text'),
			majik_validation_alert_text: this.i18n.get('majik_validation_alert_text'),
			majik_validation_alert_title: this.i18n.get('majik_validation_alert_title'),
			// analytics
			majik_ca_inapp_dock_header: this.i18n.get('inapp_message_analytics'),
			majik_ce_analytics: this.i18n.get('majik_ce_analytics'),
			majik_ce_activity_summary: this.i18n.get('majik_ce_activity_summary')
		};
	}

	initializeMCLib (endpoints) {
		// In QA1SS1, endpoints.stackHost will be `mc.s1.qa1.exacttarget.com`
		const stackHostProtocol = 'https://';
		const allowedOrigin =  stackHostProtocol + endpoints.stackHost;

		this.mcLib = new MCLib({
			mc: window.top,
			allowedOrigin: allowedOrigin,
			callback: () => {
				setTimeout(() => {
					this.mcLib.registerLogoutUrl(`${window.location.origin}/logout`);
					this.mcLib.getBusinessRules((data) => { // eslint-disable-line no-unused-vars
						PostmongerStore.mcLib = this.mcLib;
						
						const SCMViews = {
							summary: this.createSummaryView,
							messageDefinition: this.createMessageDefinitionView,
							messageConfiguration: this.createMessageConfigView,
							messageTrigger: MessageTriggerView,
							ftuxMessageTrigger: FtuxMessageTrigger,
							deliveryOptions: this.createDeliveryOptionsView,
							advancedOptions: this.createAdvancedOptionsView
						};
						
						this.scm = new MAJIK.StandardConfigModel({
							sdk: this.activitySDK,
							element: this.majik,
							defaultView: 'summary',
							localization: this.localization,
							navIcon: () => (appIcon),
							headerIcon: () => (appIcon),
							sldsPath: '/assets',
							views: SCMViews,
							extensions: [
								{
									extension: MAJIKMessageActivity.MessageActivity,
									options: {
										summaryView: {
											showDetailViewButton: true,
											showAnalyticsToggle: true,
											isActivityNameRequired: true
										},
										messageDefinition: {
											showPreviewSubscriber: true,
											showLegacyMessageTab: false,
											getAssetId: (scm) => {
												const { activity } = scm.sdk;

												if (activity && hasProperty(activity, 'configurationArguments') && hasProperty(activity.configurationArguments, 'assetId')) {
													return activity.configurationArguments.assetId;
												}

												return null;
											},
											contentBuilder: {
												grid: {
													defaultView: 'thumbnail',
													query: {
														acceptedTypes: [230],
														channelViews: ['inApp']
													}
												},
												editor: {
													view: 'inApp',
													asset: {
														assetType: {
															id: 230
														}
													}
												},
												previewSubscriber: {
													view: 'inApp',
													previewData: null,
													recipientData: null,
													dataSource: null
												}
											}
										}
									}
								},
								{
									extension: MAJIKChannelAnalytics.Extension,
									options: {
										getStack: function (scm) {
											return scm.sdk.endpoints.stackKey;
										},
										getId: function (scm) {
											if (isEmpty(scm) || isEmpty(scm.sdk) || isEmpty(scm.sdk.activity) || !hasProperty(scm.sdk.activity, 'id')) {
												return null;
											}

											return scm.sdk.activity.id;
										},
										route: 'inapp/:id/dock'
									}
								},
								{
									extension: betterValidationExtension,
									options: {
										saveButtonId: 'done-btn'
									}
								}
							],
							onLoad: function (done, scm) {
								const { store, sdk, xt, isReadOnly } = scm;
								const { activity } = sdk;

								if (activity && hasProperty(activity, 'metaData') && hasProperty(activity.metaData, 'store') && activity.metaData.store) {
									// EDIT Mode - activity data exists
									scm.store = {
										...activity.metaData.store,
										...scm.store // In case of preloaded content
									};

									xt.enableSpoke('messageConfiguration');
									xt.enableSpoke('messageTrigger');
									xt.enableSpoke('deliveryOptions');
									xt.enableSpoke('advancedOptions');
									
									// Fetch list of applications for currently selected application
									if (!isReadOnly()) {
										fetchApplicationTriggerEvents(activity.configurationArguments.application.id).then((response) => {
											if (!response || !response.customEvents) {
												scm.store.messageTrigger.messageTriggerEventOptions = [];
												return;
											}

											scm.store.messageTrigger.messageTriggerEventOptions = response.customEvents.map((event) => ({
												...event,
												type: MESSAGE_TRIGGER_EVENT_TYPE_MAP[event.eventType],
												id: event.pushEventDefinitionId
											}));
										});
									}
									
									// if existing data does not have Message Trigger data, initialize its scm.store data and set old data indicator flag
									if (!hasProperty(scm.store, 'messageTrigger')) {
										scm.store.messageTrigger = {};
										PostmongerStore.isEditingOldActivity = true;
									}
								} else {
									// Brand new activity from scratch - initialize metadata store
									store.messageDefinition = {}; // load data when edit
									store.messageConfiguration = {}; // load data when edit
									store.advancedOptions = {}; // load data when edit
									store.deliveryOptions = {}; // load data when edit
									store.messageTrigger = {};
								}
								
								done();
							}
						});
						
						if (PostmongerStore.devMode) {
							this.scm.registerExtension({
								extension: MAJIK.extensions.SCMDrawer
							});
						}
						
						const isAnalyticsMode = hasProperty(this.scm.sdk, 'activity') && hasProperty(this.scm.sdk.activity, 'editable') && hasProperty(this.scm.sdk.activity, 'configurationArguments') && this.scm.sdk.activity.editable === false && hasProperty(this.scm.sdk.activity.configurationArguments, 'assetId') && this.scm.sdk.activity.configurationArguments.assetId;

						if (!isAnalyticsMode) {
							// Get list of push applications
							this.props.fetchMobileApps();
						}
						
						this.scm.render();
					});
				});
			}
		});
	}
	
	setMessageTriggerPayload (payload, dataSource) {
		const triggerLogicMap = {
			all: 'AND',
			any: 'OR'
		};
		
		payload.messageTrigger = dataSource.messageTriggerEvents.map((event) => {
			if (event.trigger === 'always') { // For Always trigger, Don't include filterDefinitionJSON data since there is no filter for Always trigger
				return {
					eventName: event.name,
					eventId: event.id,
					eventType: event.eventType
				};
			}
			
			const configuredAttributesForCurrentEvent = dataSource.configuredAttributes.filter((attribute) => attribute.eventId === event.id);
			const attributePayload = configuredAttributesForCurrentEvent.map((attribute) => ({
				id: attribute.resource,
				operator: attribute.operator,
				conditionValue: attribute.value,
				conditionValueType: attribute.resourceType[0].toUpperCase() + attribute.resourceType.slice(1) // Capitalize first letter of resource type
			}));
			
			return {
				eventName: event.name,
				eventId: event.id,
				eventType: event.eventType,
				filterDefinition: {
					conditionSet: {
						triggerOperator: triggerLogicMap[event.trigger],
						condition: attributePayload
					}
				}
			};
		});
	}

	createSummaryView (scm) {
		return {
			onInitialize: function () {
				const { store, sdk } = scm;

				if (hasProperty(sdk.activity, 'name')) {
					this.setActivityName(sdk.activity.name);
					store.activityName = sdk.activity.name;
				}

				if (hasProperty(sdk.activity, 'metaData') && hasProperty(sdk.activity.metaData, 'globals') && hasProperty(sdk.activity.metaData.globals, 'description')) {
					this.setDescription(sdk.activity.metaData.globals.description);
					store.activityDescription = sdk.activity.metaData.globals.description;
				}

				scm.xt.setDirtyState(true); // Enable cancel confirmation dialog

				// Initialize store
				if (!hasProperty(store, 'messageDefinition')) {
					sectionKeys.forEach((key) => {
						store[key] = {};
					});

					console.log(store);
				}

				this.updateDoneButton();
			},
			onActivityNameUpdated: function (e) {
				// Callback when the activity name input is updated.
				scm.store.activityName = e.value;

				this.updateDoneButton();
			},
			isConfigured: () => {
				const { store } = scm;
				let isConfigured;

				// Check if activity name is blank
				isConfigured = hasProperty(store, 'activityName') && store.activityName.trim().length !== 0;

				// Check Reporting Name
				isConfigured = isConfigured && hasProperty(store.advancedOptions, 'mobilePushReportingName') && store.advancedOptions.mobilePushReportingName.length > 0;

				// Check Application data
				isConfigured = isConfigured && hasProperty(store.messageConfiguration, 'application') && hasProperty(store.messageConfiguration.application, 'id') && hasProperty(store.messageConfiguration.application, 'name') && store.messageConfiguration.application.id.length > 0 && store.messageConfiguration.application.name.length > 0;

				// Check startDate
				isConfigured = isConfigured && hasProperty(store.messageConfiguration.payload, 'startDate') && store.messageConfiguration.payload.startDate.length > 0;

				// scheduledTzOffset check
				isConfigured = isConfigured && hasProperty(store.messageConfiguration.payload, 'scheduledTzOffset') && store.messageConfiguration.payload.scheduledTzOffset !== null;

				return isConfigured;
			},
			updateDoneButton: function () {
				if (this.isConfigured()) {
					scm.enableFooterButton(MAJIK_DONE_BUTTON_ID);
				} else {
					scm.disableFooterButton(MAJIK_DONE_BUTTON_ID);
				}
			},
			onDescriptionUpdated: (e) => {
				// Callback when the activity description input is updated.
				scm.store.activityDescription = e.value;
			},
			onDetailViewClicked: function () {
				this.toggleDetailView();
			},
			onDetailedViewOpened: function (e, args) {
				const { content } = args.store.messageDefinition;
				const { element } = e;

				if (isDetailViewDataAvailable(content)) {
					this.summaryDetailView = new SummaryDetail(element, { // eslint-disable-line react/no-unused-class-component-methods
						content,
						previewHtml: hasProperty(content, 'views') && hasProperty(content.views, 'inApp') && hasProperty(content.views.inApp, 'content') ? content.views.inApp.content : content.content
					});
				}
			},
			onDoneClicked: (args) => {
				const { sdk, close, xt, store, isReadOnly } = args;
				const { activity } = sdk;
				const messageDefinitionStore = store.messageDefinition;
				const messageConfigurationStore = store.messageConfiguration;
				const messageTriggerStore = store.messageTrigger;
				const deliveryOptionsStore = store.deliveryOptions;
				const advancedOptionsStore = store.advancedOptions;

				if (isReadOnly()) {
					// On read only mode, just close the activity without saving
					xt.setDirtyState(false); // Disable cancel confirmation dialog on close

					close();

					return;
				}

				store.isReportingNameSupported = false; // Since reporting name is no longer supported, we set this flag to false by default. This flag is used to determine whether the activity was created before or after the reporting name deprecation.

				// Save Activity Name - If it's empty, set it with selected content's name
				if (!store.activityName || store.activityName.length === 0) {
					store.activityName = messageDefinitionStore.content.name;
				}
				activity.name = store.activityName;

				// Save Activity Description
				if (store.activityDescription) {
					sdk.setGlobals('description', store.activityDescription);
				} else {
					sdk.setGlobals('description', '');
				}

				// Save data to configurationArguments
				// Save required properties
				activity.configurationArguments = {
					...activity.configurationArguments,
					// Save Message Definition
					assetId: messageDefinitionStore.content.id,
					name: messageDefinitionStore.content.name,
					// Save Message Configuration
					application: {
						id: messageConfigurationStore.payload.application.id,
						name: messageConfigurationStore.payload.application.name
					},
					// Save schedule
					startDate: messageConfigurationStore.payload.startDate,
					scheduledTzOffset: messageConfigurationStore.payload.scheduledTzOffset,
					scheduledTimezone: messageConfigurationStore.payload.scheduledTimezone,
					// Save Delivery Options
					eventTrigger: deliveryOptionsStore.payload.eventTrigger,
					// Save Advanced Options
					mobilePushReportingName: advancedOptionsStore.payload.mobilePushReportingName,
					// Default
					tzBased: false,
					contentAvailable: 1
				};
				
				// Remove event trigger related data from payload
				delete activity.configurationArguments.eventTrigger;
				this.setMessageTriggerPayload(activity.configurationArguments, messageTriggerStore);

				// Save optional properties
				// Start Date: Reset start date if it's not custom timeframe
				if (!messageConfigurationStore.isCustomTimeframe) {
					const currentStartDate = moment().toISOString(); // Current time

					activity.configurationArguments.startDate = currentStartDate;
					messageConfigurationStore.payload.startDate = currentStartDate;
					delete activity.configurationArguments.endDate;
				}

				// End Date
				if (messageConfigurationStore.isCustomTimeframe && hasProperty(messageConfigurationStore.payload, 'endDate')) {
					activity.configurationArguments.endDate = messageConfigurationStore.payload.endDate;
				} else {
					delete activity.configurationArguments.endDate;
				}
				
				// Dynamic Timeframe
				if (messageConfigurationStore.isDynamicTimeframe) {
					// Remove end date
					delete activity.configurationArguments.endDate;
					
					activity.configurationArguments.dynamicTimeframe = messageConfigurationStore.dynamicTimeframe;
					activity.configurationArguments.dynamicTimeframeUnit = messageConfigurationStore.dynamicTimeframeUnit;
				} else {
					// Clear dynamic timeframe related data from payload
					delete activity.configurationArguments.dynamicTimeframe;
					delete activity.configurationArguments.dynamicTimeframeUnit;
				}

				// Delivery Options: Message priority
				if (hasProperty(deliveryOptionsStore.payload, 'priority')) {
					activity.configurationArguments.priority = parseInt(deliveryOptionsStore.payload.priority, 10);
				} else {
					delete activity.configurationArguments.priority;
				}
				
				// Delay Message Display
				if (hasProperty(deliveryOptionsStore, 'delayDisplayValue')) {
					activity.configurationArguments.messageDelayDisplay = deliveryOptionsStore.delayDisplayValue;
					activity.configurationArguments.messageDelayDisplayUnit = deliveryOptionsStore.delayDisplayUnit;
				} else {
					delete activity.configurationArguments.messageDelayDisplay;
					delete activity.configurationArguments.messageDelayDisplayUnit;
				}

				// Delete Content from metadata. It will be fetched during edit flow.
				delete messageDefinitionStore.content;

				// Save store to metaData
				activity.metaData = activity.metaData || {};
				activity.metaData.store = store;

				console.log(activity.configurationArguments); // Debugging purpose
				console.log(activity.metaData.store);

				sdk.saveAndClose();

				xt.setDirtyState(false); // Disable cancel confirmation dialog on close

				close();
			},
			onShow: function (args) {
				// Done Button State update
				const { hideFooterButton, showFooterButton, store } = args;

				// Render activity name and description
				this.setActivityName(store.activityName);
				this.setDescription(store.activityDescription);

				this.updateDoneButton();

				// Check content selection
				if (!isMessageSelected(store)) {
					return;
				}

				// Update Detail button visibility
				if (isApplicationSelected(store) && isDetailViewDataAvailable(store.messageDefinition.content)) {
					showFooterButton(MAJIK_DETAIL_BUTTON_ID);
				} else {
					hideFooterButton(MAJIK_DETAIL_BUTTON_ID);
				}

				// Save configurationArguments to window.inapp for testing purpose.
				if (hasProperty(args.sdk, 'activity') && hasProperty(args.sdk.activity, 'configurationArguments') && !isEmpty(args.sdk.activity.configurationArguments)) {
					window.inapp.JBPayload = args.sdk.activity.configurationArguments;
				}
			}
		};
	}

	createMessageDefinitionView () {
		const viewIndex = 0;
		const sectionKey = sectionKeys[viewIndex];

		return {
			onContentChanged: (e, args) => {
				const { sdk, xt, store } = args;
				const { activity } = sdk;

				// Required UI Logic:
				if (e.content) {
					const { content } = e;

					/* Initialize Default Data */
					if (!hasProperty(activity, 'configurationArguments')) {
						activity.configurationArguments = {};
					}

					// save activity name with content name
					store.activityName = content.name;

					const isMessagePreloading = (!hasProperty(store, sectionKey) || !hasProperty(store[sectionKey], 'content')) && hasProperty(activity.configurationArguments, 'assetId'); // Check if message is being pre-loaded by Majik via getAssetId()

					if (isMessagePreloading) {
						// Just save loaded content to store and exit
						if (!hasProperty(store, 'messageDefinition')) {
							store.messageDefinition = {
								content: content
							};
						} else {
							store.messageDefinition.content = content;
						}

						return;
					}

					xt.enableSpoke('messageConfiguration');
					xt.enableSpoke('deliveryOptions');
					xt.enableSpoke('advancedOptions');

					if (isApplicationSelected(store)) {
						xt.enableSpoke('messageTrigger');
					}

					// save content to store
					store[sectionKey].content = content;

					/* Initial Data Setup */
					// For Delivery Options
					if (!hasProperty(store.deliveryOptions, 'payload')) {
						store.deliveryOptions.payload = {};
					}

					// For Advanced Options
					if (!hasProperty(store.advancedOptions, 'payload')) {
						store.advancedOptions.payload = {};
					}

					if (hasProperty(store.advancedOptions.payload, 'mobilePushReportingName') && store.advancedOptions.payload.mobilePushReportingName.indexOf(content.name) !== -1) {
						return; // Skip if the reporting name has already been generated with the selected content
					}

					store.advancedOptions.payload.mobilePushReportingName = `${content.name}-${this.GUIDForMobilePushReportingName}`;
					store.advancedOptions.mobilePushReportingName = `${content.name}-${this.GUIDForMobilePushReportingName}`;
				} else {
					// On deselect content
					delete store[sectionKey].content;

					xt.disableSpoke('messageConfiguration');
					xt.disableSpoke('deliveryOptions');
					xt.disableSpoke('advancedOptions');
				}
			},
			onRenderMessageCard: (e, args) => {
				const { content } = e;

				// the icon that appears in the card header
				e.cardOptions.cardIconHtml = messageCardAppIcon;

				// the list of name & values that appear in the card's message properties section
				e.cardOptions.metadata.values = [];

				if (hasProperty(content, 'modifiedDate')) {
					e.cardOptions.metadata.values.push({
						text: this.i18n.get('majik_label_metadata_last_modified'),
						value: moment(content.modifiedDate).format('LL')
					});
				}

				if (hasProperty(content, 'createdBy') && hasProperty(content.createdBy, 'name')) {
					e.cardOptions.metadata.values.push({
						text: this.i18n.get('majik_label_metadata_created_by'),
						value: sanitizeHTML(content.createdBy.name)
					});
				}

				if (hasProperty(content, 'createdDate')) {
					e.cardOptions.metadata.values.push({
						text: this.i18n.get('majik_label_metadata_created_date'),
						value: moment(content.createdDate).format('LL')
					});
				}

				if (args.isReadOnly()) {
					// Update card button options - Only show subscriber preview
					e.cardOptions.primaryButtonText = this.i18n.get('majik_button_subscriber_preview');

					e.cardOptions.onPrimaryButtonClicked = () => {
						args.show('messageDefinition', {
							action: 'preview-subscriber'
						});
					};

					e.cardOptions.dropdownList = [];
				}
			},
			onCreateSubscriberPreview: function (e, args) {
				const { store } = args;
				const messageConfigurationStore = store.messageConfiguration;
				const { options } = e;

				// Set application data option if application has been selected
				if (isApplicationSelected(store)) {
					options.appId = messageConfigurationStore.application.id;
					options.previewData = messageConfigurationStore.application;
				}
			},
			onCreateContentBuilderEditor: function (e, args) {
				const { store } = args;
				const messageConfigurationStore = store.messageConfiguration;
				const { options } = e;

				// Set application data option if application has been selected
				if (isApplicationSelected(store)) {
					options.appId = messageConfigurationStore.application.id;
					options.previewData = messageConfigurationStore.application;
				}
			}
		};
	}

	createMessageConfigView (scm) {
		const viewIndex = 1;
		const sectionKey = sectionKeys[viewIndex];

		return {
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('inapp_message_configuration'),
				required: true,
				disabled: true,
				description: () => {
					const { views } = scm;
					
					if (isMessageSelected(scm.store)) {
						const spokeValidationState = find(views(), {
							name: sectionKey
						}).spoke.validationState;
						
						if (spokeValidationState === 'error') {
							return `<div>${this.i18n.get('finish_configuration')}</div>`;
						}
						
						return '';
					}

					return `<div class="slds-text-color_weak">${this.i18n.get('inapp_message_configuration_description')}</div>`;
				},
				metadata: (element, args) => {
					if (!isMessageSelected(args.store)) {
						return;
					}
					
					const spokeValidationState = find(args.views(), {
						name: sectionKey
					}).spoke.validationState;
					
					if (spokeValidationState === 'error') {
						return;
					}

					const { xt, store } = args;
					const { application, startDate, startTime, endDate, endTime, timezoneLabel, isCustomTimeframe, isDynamicTimeframe, dynamicTimeframe, dynamicTimeframeUnit } = store[sectionKey];
					const metaDataValues = [];

					// Mobile App Name
					metaDataValues.push({
						text: this.i18n.get('mobile_app_selection_dropdown_label'),
						value: application ? application.value : '',
						title: application ? application.title : ''
					});

					// Active Messgae Timeframe
					let messageTimeframeValue;
					
					if (isCustomTimeframe) {
						messageTimeframeValue = this.i18n.get('active_message_timeframe_custom_timeframe');
					} else if (isDynamicTimeframe) {
						messageTimeframeValue = this.i18n.get('message_expiration_dynamic_timeframe');
					} else {
						messageTimeframeValue = this.i18n.get('active_message_timeframe_always_active_default');
					}
					
					metaDataValues.push({
						text: this.i18n.get('message_expiration'),
						value: messageTimeframeValue
					});

					if (isCustomTimeframe) {
						// Start Date
						metaDataValues.push({
							text: this.i18n.get('custom_timeframe_scheduler_start_date'),
							value: (startDate && startTime) ? this.i18n.get('start_date_at_start_time').replace('{startDate}', startDate).replace('{startTime}', startTime) : ''
						});

						// Optional End date
						if (endDate && endTime) {
							metaDataValues.push({
								text: this.i18n.get('custom_timeframe_scheduler_end_time'),
								value: this.i18n.get('end_date_at_end_time').replace('{endDate}', endDate).replace('{endTime}', endTime)
							});
						}

						// Timezone
						metaDataValues.push({
							text: this.i18n.get('custom_timeframe_scheduler_timezone'),
							value: timezoneLabel
						});
					} else if (isDynamicTimeframe) {
						const unitLocalizationMap = {
							mi: this.i18n.get('minutes'),
							hh: this.i18n.get('hours'),
							dd: this.i18n.get('days'),
							ww: this.i18n.get('weeks'),
							mm: this.i18n.get('months')
						};
						
						metaDataValues.push({
							text: this.i18n.get('duration'),
							value: `${dynamicTimeframe} ${unitLocalizationMap[dynamicTimeframeUnit]}`
						});
					}

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('message_configuration_section_heading')
			},
			onShow: (args) => {
				const { element, sdk, xt, store, showLoadMask, hideLoadMask, views, createModal, resize } = args;
				const { activity } = sdk;
				const { route } = this.props;
				
				const menuValidationState = find(views(), {
					name: sectionKey
				}).menu.validationState;

				const onTestSendsButtonClick = (appPayload, msgPayload, i18n) => { // eslint-disable-line no-unused-vars
					const testSendsModal = {
						id: 'testSendsModal',
						onHeaderBackButtonClicked: () => {
							if (element.querySelector('.test-sends-content-wrapper')) {
								resize('scm-lg');
								scm.views().forEach((view) => {
									if (view.name === 'messageConfiguration') {
										view.onShow(args);
									}
								});
							}
						},
						onInitialize: function () {
							element.innerHTML = '<div id="test-send"></div>';
							ReactDOM.render(
								<TestSendsModal
									appPayload={appPayload}
									msgPayload={msgPayload}
									i18n={i18n}
									onHeaderBackButtonClicked={this.onHeaderBackButtonClicked}
								/>,
								document.getElementById('test-send')
							);
						}
					};
					createModal(testSendsModal);
				};

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				observeAndUnmountReact(element.firstChild);

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				ReactDOM.render(
					<Provider store={route.store}>
						<IconSettings iconPath="/assets/icons">
							<MessageConfiguration
								i18n={this.i18n}
								activity={activity}
								sdk={sdk}
								sectionKey={sectionKey}
								xt={xt}
								dataStore={store}
								showLoadMask={showLoadMask}
								hideLoadMask={hideLoadMask}
								mcLib={this.mcLib}
								menuValidationState={menuValidationState}
								onTestSendsButtonClick={onTestSendsButtonClick}
								resize={resize}
							/>
						</IconSettings>
					</Provider>,
					document.getElementById(sectionKey)
				);
			},
			onHeaderBackButtonClicked: function (e, args) {
				const { resize, element } = args;
				if (element.querySelector('.test-sends-content-wrapper')) {
					e.cancel = true;
					resize('scm-lg');
					this.onShow(args);
				}
			},
			configured: () => {
				const { store } = scm;
				let isConfigured;
				
				// Check Application data
				isConfigured = hasProperty(store.messageConfiguration, 'application') && hasProperty(store.messageConfiguration.application, 'id') && hasProperty(store.messageConfiguration.application, 'name') && store.messageConfiguration.application.id.length > 0 && store.messageConfiguration.application.name.length > 0;

				// Check startDate
				isConfigured = isConfigured && hasProperty(store.messageConfiguration.payload, 'startDate') && store.messageConfiguration.payload.startDate.length > 0;

				// scheduledTzOffset check
				isConfigured = isConfigured && hasProperty(store.messageConfiguration.payload, 'scheduledTzOffset') && store.messageConfiguration.payload.scheduledTzOffset !== null;
				
				return isConfigured;
			},
			onValidate: () => {
				const { store } = scm;
				const { dynamicTimeframe, isDynamicTimeframe } = store[sectionKey];
				
				if (!isDynamicTimeframe) {
					return {
						errors: false
					};
				}
				
				return {
					errors: this.props.dynamicTimeframeError !== null || dynamicTimeframe.length === 0
				};
			}
		};
	}

	createDeliveryOptionsView (scm) {
		const viewIndex = 3;
		const sectionKey = sectionKeys[viewIndex];

		return {
			menu: {
				text: this.i18n.get('display_options')
			},
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('display_options'),
				required: false,
				disabled: true,
				description: () => {
					const store = scm.store[sectionKey];
					
					const spokeValidationState = find(scm.views(), {
						name: sectionKey
					}).spoke.validationState;
					
					if (spokeValidationState === 'error') {
						return `<div>${this.i18n.get('finish_configuration')}</div>`;
					}
					
					if (store.priority || store.delayDisplayValue || store.displayCancellationValue) {
						return '';
					}
					
					return (`<div class="slds-text-color_weak">${this.i18n.get('display_options_section_subheading')}</div>`);
				},
				metadata: (element, args) => {
					if (!isMessageSelected(args.store)) {
						return;
					}
					
					const spokeValidationState = find(args.views(), {
						name: sectionKey
					}).spoke.validationState;
					
					if (spokeValidationState === 'error') {
						return;
					}

					const { xt, store } = args;
					const { priority, delayDisplayValue, delayDisplayUnit, displayCancellationValue } = store[sectionKey];
					const metaDataValues = [];
					const unitLocalizationMap = {
						ss: this.i18n.get('seconds'),
						mi: this.i18n.get('minutes')
					};
					const displayCancellationValueMap = {
						cancelAfterExit: this.i18n.get('cancel_after_exit'),
						cancelAfterCompletion: this.i18n.get('cancel_after_completion')
					};
					
					// Message Priority
					if (priority) {
						metaDataValues.push({
							text: this.i18n.get('message_priority'),
							value: priority
						});
					}
					
					// Delay Display Trigger
					if (delayDisplayValue) {
						metaDataValues.push({
							text: this.i18n.get('delay_message_display'),
							value: `${delayDisplayValue} ${unitLocalizationMap[delayDisplayUnit]}`
						});
					}
					
					// Display Cancellation
					if (displayCancellationValue) {
						metaDataValues.push({
							text: this.i18n.get('display_cancellation'),
							value: displayCancellationValueMap[displayCancellationValue]
						});
					}

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('display_options')
			},
			onShow: (args) => {
				const { element, sdk, store } = args;
				const { activity } = sdk;
				const { route } = this.props;

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				observeAndUnmountReact(element.firstChild);

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				ReactDOM.render(
					<Provider store={route.store}>
						<IconSettings iconPath="/assets/icons">
							<DeliveryOptions
								i18n={this.i18n}
								sectionKey={sectionKey}
								dataStore={store}
							/>
						</IconSettings>
					</Provider>,
					document.getElementById(sectionKey)
				);
			},
			configured: () => {
				const { store } = scm;
				const { priority, delayDisplayValue, displayCancellationValue } = store[sectionKey];
				
				return priority || delayDisplayValue || displayCancellationValue;
			},
			onValidate: () => {
				if (!this.props.isDelayDisplayToggleEnabled) {
					return {
						errors: false
					};
				}
				
				return {
					errors: !!this.props.delayDisplayError
				};
			}
		};
	}

	createAdvancedOptionsView (scm) {
		const viewIndex = 4;
		const sectionKey = sectionKeys[viewIndex];

		return {
			spoke: {
				index: viewIndex + 1, // Majik expects non-array indexing
				text: this.i18n.get('inapp_advanced_options'),
				required: false,
				disabled: true,
				description: () => (`<div class="slds-text-color_weak">${this.i18n.get('inapp_advanced_options_description')}</div>`),
				metadata: (element, args) => {
					if (!isMessageSelected(args.store)) {
						return;
					}

					const { xt, store } = args;
					const { mobilePushReportingName } = store[sectionKey];
					const metaDataValues = [];

					// Mobile Push Reporting Name
					if (scm.isReadOnly() && !(typeof (store.isReportingNameSupported) !== 'undefined' && store.isReportingNameSupported === false)) {
						metaDataValues.push({
							text: this.i18n.get('mobilepush_reporting_name'),
							value: sanitizeHTML(mobilePushReportingName)
						});
					}

					xt.renderMetadata(element, {
						values: metaDataValues
					});
				}
			},
			header: {
				text: this.i18n.get('advanced_options_section_heading')
			},
			onShow: (args) => {
				const { element, sdk, store } = args;
				const { activity } = sdk;
				const { route } = this.props;

				element.innerHTML = `<div id="${sectionKey}"></div>`;

				observeAndUnmountReact(element.firstChild);

				if (!hasProperty(activity, 'configurationArguments')) {
					activity.configurationArguments = {};
				}

				ReactDOM.render(
					<Provider store={route.store}>
						<IconSettings iconPath="/assets/icons">
							<AdvancedOptions
								i18n={this.i18n}
								sectionKey={sectionKey}
								dataStore={store}
							/>
						</IconSettings>
					</Provider>,
					document.getElementById(sectionKey)
				);
			}
		};
	}

	componentDidMount () {
		if (PostmongerStore.devMode) {
			this.activitySDK.requestInteraction = (callback) => {
				setTimeout(() => {
					callback({
						status: 'DRAFT' // Mock Analytics flow: Change this to RUNNING
					});
				}, 200);
			};

			this.activitySDK.ready = (callback) => {
				// Mock endpoints
				this.activitySDK.endpoints = {
					stackKey: 'QA1S4',
					stackHost: 'mc.s4.qa1.exacttarget.com'
				};
				
				// Uncomment to Mock Edit Flow 
				// this.activitySDK.activity = EDIT_FLOW_MOCK_DATA;
				
				// Uncomment to Mock Analytics Flow
				// this.activitySDK.activity = ANALYTICS_FLOW_MOCK_DATA;

				this.initializeMCLib(this.activitySDK.endpoints);
				callback();
			};
		}

		// This is where the SCM object gets rendered on to the page
		// Use the activitySDK.ready() function to start the handshake with Journey Builder
		// The callback will fire when JB passes the activity back to us.
		this.activitySDK.ready(() => {
			this.initializeMCLib(this.activitySDK.endpoints);
		});
		fetchSettings(); // Fetch feature flags
	}

	render () {
		return (
			<div className="mobilepush-inapp-actvity" ref={(el) => { this.majik = el; }} />
		);
	}
}

App.propTypes = {
	fetchMobileApps: PropTypes.func,
	route: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	mobileApps: state.MobileAppSelection.mobileApps,
	isDelayDisplayToggleEnabled: state.DisplayOptions.isDelayDisplayToggleEnabled,
	dynamicTimeframeError: state.DynamicTimeframe.dynamicTimeframeError,
	delayDisplayError: state.DisplayOptions.delayDisplayError
});

const mapDispatchToProps = (dispatch) => ({
	fetchMobileApps: () => dispatch(fetchMobileApps)
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	hot(module)
)(App);
