import Alert from '@salesforce-mc/mobile-common/components/alert';
import Template from './mobile-application.html';
import Combobox from '../../common/combobox';
import { hasProperty } from '../../../utilities/helper';
import fetchApplicationTriggerEvents from '../../../actions/applications';
import { MESSAGE_TRIGGER_EVENT_TYPE_MAP } from '../../../constants';

export default function (dom, configs) {
	const { i18n, applications, sectionKey, isFetching, xt, store, showLoadMask, hideLoadMask, setMessageTriggerEvents, updateEventAttributes, renderTestSends } = configs;
	const currentApplication = hasProperty(store[sectionKey], 'application') ? applications.find((app) => app.id === store[sectionKey].application.id) : undefined;

	const renderExpiredMessage = (application) => {
		const container = dom.querySelector('#alert-container');

		if ((application) && (application.expired)) {
			const options = {
				type: 'warning',
				message: i18n.get('expired_application_warning')
			};

			Alert(container, options);
		} else {
			container.innerHTML = '';
		}
	};

	const onFetchSuccess = () => {
		hideLoadMask();
		renderTestSends();
		xt.enableSpoke('messageTrigger');
		xt.enableSpoke('deliveryOptions');
		xt.enableSpoke('advancedOptions');
	};

	const onFetchFail = () => {
		hideLoadMask();
	};

	const saveMobileApplication = (application) => {
		const messageConfigurationStore = store[sectionKey];
		const messageTriggerStore = store.messageTrigger;
		
		// If application selection is being changed and there are existing configured trigger data, wipe out trigger data
		if (messageConfigurationStore.application && messageTriggerStore.messageTriggerEvents && messageTriggerStore.configuredAttributes) {
			messageTriggerStore.messageTriggerEvents = [];
			messageTriggerStore.configuredAttributes = [];
			setMessageTriggerEvents([]);
			updateEventAttributes([]);
		}

		delete messageConfigurationStore.application;
		delete messageConfigurationStore.payload.application;

		if ((application) && (application.id)) {
			messageConfigurationStore.payload.application = {
				id: application.id,
				name: application.name
			};

			// Update metadata
			messageConfigurationStore.application = (({ iconUrl, ...others }) => ({ ...others }))(application); // Store application data without iconUrl to reduce payload size

			// Show loader until message detail fetch is completed
			showLoadMask({
				transparent: true
			});
			
			const postAppSelectionFetchPromise = [];
			
			postAppSelectionFetchPromise.push(new Promise((resolve, reject) => {
				fetchApplicationTriggerEvents(application.id).then((data) => {
					if (!data || !data.customEvents) {
						messageTriggerStore.messageTriggerEventOptions = [];
						resolve({});
						return;
					}

					messageTriggerStore.messageTriggerEventOptions = data.customEvents.map((event) => ({
						...event,
						type: MESSAGE_TRIGGER_EVENT_TYPE_MAP[event.eventType], // Event type come back as enum value but UI needs them in string format
						id: event.pushEventDefinitionId
					}));

					resolve(data);
				}, () => { // On fetch fail
					reject();
				});
			}));
			
			Promise.all(postAppSelectionFetchPromise).then(() => {
				const isRequirementSatisfied = hasProperty(messageConfigurationStore.payload, 'application') && hasProperty(messageConfigurationStore.payload.application, 'id') && hasProperty(messageConfigurationStore.payload.application, 'name') && messageConfigurationStore.payload.application.id.length > 0 && messageConfigurationStore.payload.application.name.length > 0;

				if (isRequirementSatisfied) {
					// When fetch is completed, remove the loader
					onFetchSuccess();
				}
			}, () => {
				// On fail
				onFetchFail();
			});
		}
	};

	const renderComboBox = () => {
		const container = dom.querySelector('#combobox-container');
		const options = {
			label: i18n.get('mobile_app_selection_dropdown_label'),
			id: 'mobile-app-selection-combobox',
			placeholder: i18n.get('mobile_app_selection_dropdown_placeholder'),
			noOptionsFoundText: 'No applications found',
			selection: currentApplication || {},
			required: true,
			hasIconUrl: true,
			options: applications || [],
			isFetching: isFetching || false
		};
		const combobox = new Combobox(container, options);

		combobox.onSelect = (selectedApplication) => {
			renderExpiredMessage(selectedApplication);
			saveMobileApplication(selectedApplication);
		};
	};

	const renderHeader = () => {
		const header = i18n.get('mobile_app_selection_heading');

		dom.querySelector('#header-container').innerHTML = header;
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const initialize = () => {
		render();
		renderHeader();
		renderComboBox();

		if ((currentApplication) && (Object.keys(currentApplication).length !== 0)) {
			renderExpiredMessage(currentApplication);
		}
	};

	initialize();
}
