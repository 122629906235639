import React from 'react';
import { Radio } from '@salesforce/design-system-react/components';
import PropTypes from 'prop-types';
import VisualPicker from '@salesforce/design-system-react/components/visual-picker';
import { CONTACT_ID } from './TestSendConstants';

/* eslint-disable react/no-unstable-nested-components */

class TestMethodContent extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			contactId: props.contactId
		};
		this.i18n = this.props.i18n;
		this.testMethodClickHandler = props.testMethodClickHandler;
	}

	updateContactId = (contactId) => { // eslint-disable-line react/no-unused-class-component-methods
		this.setState({
			contactId: contactId
		});
	};

	render () {
		return (
			<VisualPicker id="test-method-content">
				<Radio
					labels={{
						heading: this.i18n.get('test_send_identifier_system_token')
					}}
					id="system-token-choice"
					value="system-token"
					onRenderVisualPicker={() => (
						<span>
							<svg aria-hidden="true" className="slds-icon slds-icon-text-default test-class slds-icon_large">
								<use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#push" />
							</svg>
						</span>
					)}
					onChange={this.testMethodClickHandler}
					checked={this.state.contactId === CONTACT_ID.SYSTEM_TOKEN}
				/>
				<Radio
					labels={{
						heading: this.i18n.get('test_send_identifier_device_id')
					}}
					id="device-id-choice"
					value="device-id"
					onRenderVisualPicker={() => (
						<span>
							<svg aria-hidden="true" className="slds-icon slds-icon-text-default test-class slds-icon_large">
								<use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#phone_portrait" />
							</svg>
						</span>
					)}
					onChange={this.testMethodClickHandler}
					checked={this.state.contactId === CONTACT_ID.DEVICE_ID}
				/>
				<Radio
					labels={{
						heading: this.i18n.get('test_send_identifier_contact_key')
					}}
					id="contact-key-choice"
					value="contact-key"
					onRenderVisualPicker={() => (
						<span>
							<svg aria-hidden="true" className="slds-icon slds-icon-text-default test-class slds-icon_large">
								<use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#contact" />
							</svg>
						</span>
					)}
					onChange={this.testMethodClickHandler}
					checked={this.state.contactId === CONTACT_ID.CONTACT_KEY}
				/>
			</VisualPicker>
		);
	}
}

TestMethodContent.propTypes = {
	testMethodClickHandler: PropTypes.func.isRequired,
	contactId: PropTypes.string.isRequired
};

export default TestMethodContent;
